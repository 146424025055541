import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { buildSellerWizardPageUrl } from '@zorba-shared/core/Services/urlBuilderService';
import { Page } from '../../components/layout';
import Footer from '../../components/layout/Footer';
import HeroSection from '../SellLandingPage/Components/HeroSection';
import styles from './DataLandingPage.module.scss';
import { setSelectedAddress } from '../../reducers/SellerWizard/actions';
import { ZorbaButton } from '../../components/forms';
import FAQSection from '../HomeownersLandingPage/Components/FAQSection';
import { DATA_PLATFORM_QUESTIONS } from '../../constants/faq';
import FeaturePicker from './Components/FeaturePicker';

export default () => {
  const [placeValue, setPlaceValue] = useState(undefined);
  const [placesLoader, setPlacesLoader] = useState(false);
  const dispatch = useDispatch();
  const router = useRouter();
  const onPlaceSelect = (val) => {
    setPlaceValue(val);
    dispatch(setSelectedAddress(val));
    if (!val) return;
    setPlacesLoader(true);

    setTimeout(() => {
      router.push(buildSellerWizardPageUrl());
      setPlacesLoader(false);
      setPlaceValue();
    }, 2000);
  };
  const inheritedProps = {
    placeValue,
    setPlaceValue,
    setPlacesLoader,
    placesLoader,
    onPlaceSelect,
  };

  const handleArticleClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <Page
      className={styles['data-landing-page']}
      pageInner={styles['page-inner']}
      pageInnerContent={styles['page-inner-content']}
      footer={<Footer />}
    >
      <HeroSection
        {...inheritedProps}
        title="Grow and retain your client base with powerful real estate data"
        desc="Get the industry’s most accurate real estate data and the software you need to connect with, close, and retain your most valuable clients – all in one operating system."
        img="/img/landing-pages/funds/fund-hero-visual.png"
        cta={
          <ZorbaButton
            onClick={() => {
              window.location.href = 'mailto:data@getzorba.com';
            }}
            className={styles['cta-btn']}
            fullWidth={false}
          >
            Get a demo
          </ZorbaButton>
        }
        className="property-managers-title"
      />

      <div className={styles['mid-section']}>
        <div className={styles.left}>
          <h3>The only solution you need to run a world-class real estate sales organization</h3>
          <p>

          </p>
        </div>
        <div className={styles.right}>
          <div className={styles['mid-section-item']}>
            <h5 className={styles.title}>Grow Your Client Base</h5>
            <p className={styles.desc}>
              Find your next ideal customers with real-time insights, backed by our trusted, proprietary real estate data.

            </p>
          </div>
          <div className={styles['mid-section-item']}>
            <h5 className={styles.title}>Reduce Churn</h5>
            <p className={styles.desc}>
              Monitor your existing clients’ real estate activity and take timely action to retain them and increase transaction volume.
            </p>
          </div>
          <div className={styles['mid-section-item']}>
            <h5 className={styles.title}>Stay Informed on Market Trends</h5>
            <p className={styles.desc}>
              Track your competition and access detailed information about their activities and market trends.
            </p>
          </div>
        </div>
      </div>

      <FeaturePicker />
      <div className={styles['magazine-section']}>
        <h3>Latest from Magazine</h3>
        <div className={styles['magazine-articles-container']}>
          <div className={styles['magazine-article-card']} onClick={() => handleArticleClick('https://blog.getzorba.com/2024/07/02/mastering-real-estate-sales-growth/')}>
            <img src='/img/data/data_article_1.png'></img>
            <div className={styles['magazine-article-content-container']}>
              <h5>
                Mastering Real Estate Sales Growth
              </h5>
              <p>
                A tried-and-tested framework for evaluating, optimizing, and automating your real estate sales process.
              </p>
            </div>
          </div>
          <div className={styles['magazine-article-card']} onClick={() => handleArticleClick('https://blog.getzorba.com/2024/04/04/q1-2024-sfr-off-market-acquisitions-top-markets-for-institutional-residential-real-estate-owners/')}>
            <img src='/img/data/data_article_2.jpeg'></img>
            <div className={styles['magazine-article-content-container']}>
              <h5>
                Single Family Rental Off-Market Acquisitions: Top Markets
              </h5>
              <p>
                In this analysis, we’re looking closely at the shifts in the Single Family Rental (SFR) market’s dynamics from Q4 2023 to Q1 2024.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.contact} id='contact'>
        <div className={styles.left}>{/*  */}</div>
        <div className={styles.right}>
          <p>
            Ready to own your market? Talk to our team today!
          </p>
          <h3>
            <a href="mailto:data@getzorba.com">
              data@getzorba.com
            </a>
          </h3>
        </div>
      </div>
    </Page>
  );
};
