import * as types from './types';
import { postRequest } from '@zorba-shared/client/Services/requestService';
import { showErrorToast, showSuccessToast } from '../../services/notificationsService';

export function setSelectedAddress(selectedAddress) {
  return {
    type: types.SET_SELECTED_ADDRESS,
    payload: { selectedAddress },
  };
}

export function setWizardParams(params) {
  return {
    type: types.SET_PARAMS,
    payload: {params}
  }
}

export function setPropertyMatches(propertyMatches) {
  return {
    type: types.SET_PROPERTY_MATCHES,
    payload: {propertyMatches}
  }
}

export const requestExpiredOffer = (params) => async (dispatch) => {
  try {
    const res = await postRequest('/post-match/request-expired-offer', params);
    if (!res.data.propertyMatches) {
      return showErrorToast('Requesting new offer failed.');
    }
    dispatch(setPropertyMatches(res.data.propertyMatches));
    showSuccessToast('Our team will reach out the buyer shortly to request an updated offer.');
  } catch (e) {
    showErrorToast('Requesting new offer failed.');
  }
};