import { useEffect, useState } from 'react'
import styles from './FeaturePicker.module.scss'
import { Divider } from 'antd'
import classNames from 'classnames'
const FEATURES = {
    build: {
        name: 'BUILD PIPELINE',
        items: [
            { name: 'TAM Research', img: 'feature-tam.png' },
            { name: 'Contact and account search', img: 'feature-build-pipeline.png' },
            { name: 'Scores and market insights', img: 'feature-market-insights.png' },
            // { name: 'Real estate data enrichment' },
        ]
    },
    close: {
        name: 'CLOSE DEALS',
        items: [
            { name: 'Account intelligence', img: 'feature-account-intelligence.png' },
        ]
    },
    retain: {
        name: 'ACCOUNT RETENTION',
        items: [
            { name: 'Actionable insights', img: 'feature-retention.png' },
        ]
    }

}

export default props => {
    const [feature, setFeature] = useState(FEATURES.build.items[0])


    useEffect(() => {
        Object.values(FEATURES).forEach(({ items }) => {
            items.forEach(item => {
                const img = new Image()
                img.src = `/img/landing-pages/data/${item.img}`
            })
        });
    }, [])

    return (
        <div className={styles.featurePicker}>
            <h3 className={styles.featurePickerTitle}>Zorba is the only real estate sales intelligence platform</h3>
            <p className={styles.description}>We’ve pioneered a new way to deliver actionable real estate insights, and it’s changing the game. Our AI modeling ensures unparalleled freshness and depth.</p>
            <div className={styles.inner}>
                {/* TODO */}
                <div className={styles.left}>
                    {Object.values(FEATURES).map(({ name, items }) => (
                        <>
                            <h5>{name}</h5>
                            {items.map(item => (
                                <div className={classNames(styles.feature, { [styles.selected]: feature?.name == item.name })} key={item.name} onClick={() => setFeature(item)}>
                                    <div className={styles.featureName}>{item.name}</div>
                                </div>
                            ))}
                        </>
                    ))}

                </div>

                {
                    feature?.name && <div className={styles.view}>
                        <div className={styles.header}>
                            <h4>{feature.name}</h4>
                        </div>
                        {/* <Divider /> */}
                        {feature.img && <img src={`/img/landing-pages/data/${feature.img}`} alt={feature.name} />}
                    </div>
                }
            </div>
        </div >
    )
}