import {
  buildLoginUrl,
  buildSellerWizardPageUrl,
} from '@zorba-shared/core/Services/urlBuilderService';
import { useState } from 'react';
import { reportEvent } from '@zorba-shared/client/Services/analyticsService';
import { PlacesAutoComplete, ZorbaButton } from '../../../../components/forms';
import styles from './HeroSection.module.scss';
import { setSelectedAddress } from '../../../../reducers/SellerWizard/actions';

export default (props) => {
  const {
    pageName,
    placeValue,
    onPlaceSelect,
    placesLoader,
    ctaText = 'Get my free offer',
    className,
  } = props;

  return (
    <div className={styles['top-section']}>
      <div className={styles.bg}>
        <div className={styles['bg-inner']} />
      </div>

      <div className={styles['hero-content']}>
        <div className={styles['hero-block']}>
          <h1 className={styles[className]}>{props.title}</h1>
          <p>{props.desc}</p>
          {props.cta || (
            <div className={styles['places-wrapper']}>
              <div className={styles['place-input-wrapper']}>
                <PlacesAutoComplete
                  clearInputOnSelect={false}
                  clearInputOnBlur={false}
                  showIcon={false}
                  title=""
                  placeholder={props.placeholder || '125 W Muriel Ave'}
                  onChange={onPlaceSelect}
                  allowFullAddress
                  initialInputText={placeValue?.formattedAddress || ''}
                  placesAutoCompleteStyle={styles['places-auto-complete']}
                />

                <ZorbaButton
                  className={styles['place-submit-btn']}
                  loading={placesLoader}
                  onClick={() => {
                    if (props.onSelect) {
                      props.onSelect();
                    } else {
                      router.push(buildSellerWizardPageUrl());
                      reportEvent('offer_wizard', 'click', {
                        source: 'homeownersLandingPage',
                        type: 'heroSection',
                      });
                    }
                  }}
                >
                  {ctaText}
                </ZorbaButton>
              </div>
            </div>
          )}
        </div>
        <div className={styles['hero-image-wrap']}>
          <img src={props.img} />
        </div>
      </div>
    </div>
  );
};
